<template>
    <div class="education-wrapper">
        <div class="education-header">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item><span style="font-weight: 600; color: #303133 !important;">运营推广管理</span></el-breadcrumb-item>
                <el-breadcrumb-item>商品信息</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="operation">
                <div class="category">
                    <span class="label">行业分类</span>
                    <el-select v-model="categoryId" placeholder="请选择行业分类" clearable @change="getGoodsList">
                        <el-option v-for="item in categoryList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                    </el-select>
                </div>
                <div class="btn-group">
                    <send-btn :com="this" params-type="array" :request="sendGoodsInfoAPI" ></send-btn>
                    <el-button class="btn-blue" @click="addBtn">创建商品信息</el-button>
                </div>
            </div>
        </div>
        <el-table :data="goodsInfoList" border style="width: 100%; margin-top: 20px; flex: 1" height="1%" size="medium"
                  :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#13131B', background: '#F2F3FA'}"
                  :cell-style="{fontSize: '14px',color: '#343441'}" ref="elTable">
                  <el-table-column
                    type="selection"
                    width="40">
                </el-table-column>
            <el-table-column align="center" label="商品信息名称">
                <template slot-scope="scope">
                    <p class="text-overflow-2" :title="scope.row.name">{{scope.row.name}}</p>
                </template>
            </el-table-column>
            <el-table-column align="center" label="备注">
                <template slot-scope="scope">
                    <p class="text-overflow-2" :title="scope.row.bz">{{scope.row.bz}}</p>
                </template>
            </el-table-column>
            <el-table-column prop="industry_name" align="center" label="行业分类"></el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-link :underline="false" class="link-blue" @click="copyTemplate(scope.row)">复制</el-link>
                    <el-link :underline="false" class="link-blue" @click="editBtn(scope.row)">编辑</el-link>
                    <el-link :underline="false" type="danger" @click="delBtn(scope.row.id)">删除</el-link>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="pager-center" style="margin-top: 10px;"
                       :current-page="listPages.currentPageNum"
                       :page-size="listPages.eachPageNum"
                       :total="listPages.total"
                       layout="prev, pager, next, jumper"
                       @current-change="pagesCurrentChange">
        </el-pagination>
    </div>
</template>

<script>
    import SendBtn from "../../plugs/SendTemplateBtn.vue"
    import { copyGoodsInfoTemplateAPI, sendGoodsInfoAPI } from "../../../utils/apis"
    export default {
        name:'GoodsAttr',
        data() {
            return {
                goodsInfoList: [],
                listPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
                sendGoodsInfoAPI: sendGoodsInfoAPI,
                categoryList: [],
                categoryId: void 0
            }
        },
        components: {
            SendBtn
        },
        mounted() {
            this.getCategory();
            this.getGoodsList()
        },
        activated() {
            this.getGoodsList();
        },
        methods: {
            getCategory() {
                this.$http.axiosGet(this.$api.categoryList, (res) => {
                    if (res.code === 200) {
                        this.categoryList = res.data
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            async copyTemplate(item) {
                const valid = await this.$common.copyTemplatePack(copyGoodsInfoTemplateAPI, { id: item.id });
                valid && this.getGoodsList();
            },
            // 获取商品信息列表
            getGoodsList() {
                let params = {
                    paging: 1,
                    page: this.listPages.currentPageNum,
                    pageSize: this.listPages.eachPageNum,
                    industry_id: this.categoryId
                }
                this.$http.axiosGetBy(this.$api.itemInfo, params, (res) => {
                    if (res.code === 200) {
                        this.goodsInfoList = res.data.data
                        this.listPages.total = res.data.total
                        if (this.listPages.total !== 0 && this.goodsInfoList.length === 0) {
                            this.listPages.currentPageNum--;
                            this.getGoodsList();
                        }
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            // 切换分页
            pagesCurrentChange(val) {
                this.listPages.currentPageNum = val
                this.getGoodsList()
            },
            // 添加
            addBtn() {
                this.$router.push('/admin/operatedata/goodsattr/create')
            },
            // 编辑
            editBtn(row) {
                if (row.used === 1) {
                    return this.$message.warning('该商品信息正在使用中，不可编辑')
                } else {
                    this.$router.push({
                        path: '/admin/operatedata/goodsattr/create',
                        query: {
                            id: row.id
                        }
                    })
                }
            },
            // 删除
            delBtn(id) {
                this.$confirm('是否删除该商品信息，删除完将无法恢复', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    confirmButtonClass: 'btn-blue',
                    type: 'warning',
                }).then(() => {
                    this.$http.axiosPost(this.$api.itemInfo_delete, {id: id}, (res) => {
                        if (res.code === 200) {
                            this.$message.success(res.msg)
                            this.getGoodsList()
                        } else {
                            this.$message.warning(res.msg)
                        }
                    }, (err) => {
                        console.log(err)
                    })
                }).catch(() => {
                    this.$message.info('已取消删除')
                });
            }
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep p {
        margin: 0;
    }
    .education-wrapper {
        padding: 30px;
        height: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        .education-header {
            /* display: flex;
            align-items: center;
            justify-content: space-between; */
            .el-breadcrumb {
                line-height: 40px;
            }
            .operation {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 10px;
                .category .label{
                    margin-right: 10px;
                }
            }
        }
    }
    .el-link + .el-link {
        margin-left: 10px;
    }
    /deep/ .el-table__row .is-checked .el-checkbox__inner, 
    /deep/ .has-gutter .el-checkbox .is-indeterminate .el-checkbox__inner,
    /deep/ .has-gutter .el-checkbox .is-checked .el-checkbox__inner {
        background-color: #1122d8;
        border-color: #1122d8;
    }
</style>